// const lazyLoad = {
//   bind(el, binding) {
//     const observer = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           binding.value.update()
//           observer.unobserve(el);
//         }
//       });
//     }, { threshold: 0.1});
//     observer.observe(el);
//   },
// };

// export default lazyLoad;
const lazyLoad = {
  observer: null,
  bind(el, binding) {
    lazyLoad.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          binding.value.update();
          lazyLoad.observer.unobserve(el);
        }
      });
    }, { threshold: 0.1 });
    lazyLoad.observer.observe(el);
  },
  unbind(el) {
    if (lazyLoad.observer) {
      lazyLoad.observer.unobserve(el);
    }
  }
};

export default lazyLoad;
