<template>
    <div class="people-detail-dialog">
        <el-dialog :visible.sync="visible" :width="width" :show-close="false" :custom-class="width == '351px' ? 'mobile-dialog' : ''" :before-close="handleClose">
            <div v-if="width == '351px'" class="mobile-container">
                <img class="close-icon" src="@/assets/images/dialog-close2.png" @click="handleClose">
                <img class="people-img" :src="peopleImg">
                <div class="name">{{ peopleName }}</div>
                <div v-if="peoplelicenseImg" class="license flexbox middle" @click="toggleViewer">
                    <span>证书牌照</span>
                    <i class="el-icon-d-arrow-right"></i>
                </div>
                <div class="introduction">
                    {{ peopleIntroduction }}
                </div>
            </div>
            <div v-else class="container flexbox">
                <div class="close-icon">
                    <img class="awg-cup" src="@/assets/images/dialog-close.png" @click="handleClose">
                </div>
                <div class="left-img">
                    <img :src="peopleImg">
                </div>
                <div class="right-content flexbox vertical">
                    <div class="top">
                        <span class="name">{{ peopleName }}</span>
                        <div v-if="peoplelicenseImg" class="license awg-cup" @click="toggleViewer">
                            <span>证书牌照</span>
                            <i class="el-icon-d-arrow-right"></i>
                        </div>
                    </div>
                    <div class="introduction">
                        {{ peopleIntroduction }}
                    </div>
                </div>
            </div>
            <el-image-viewer v-if="showViewer" :on-close="toggleViewer" class="awg-image-viewer" :url-list="[peoplelicenseImg]" />
        </el-dialog>
    </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';// 导入组件
export default {
    components: { ElImageViewer },
    data() {
        return {
            width:'841px',
            visible: false,
            peopleImg: '',
            peopleName: '',
            peoplelicenseImg: '',
            peopleIntroduction: '',

            showViewer: false,
        }
    },
    methods: {
        init(){
            this.peopleImg = ''
            this.peopleName = ''
            this.peoplelicenseImg = ''
            this.peopleIntroduction = ''
            this.width = window.innerWidth < 600 ? '351px' : '841px'
        },
        handleOpen(record) {
            this.init()
            this.peopleImg = record.peopleImg
            this.peopleName = record.peopleName
            this.peoplelicenseImg = record.peoplelicenseImg
            this.peopleIntroduction = record.peopleIntroduction
            this.visible = true
        },
        handleClose() {
            this.visible = false
        },

        // 关闭查看器
        toggleViewer() {
            this.showViewer = !this.showViewer
        },
    },
}
</script>
<style lang="less" scoped>
.people-detail-dialog {
    /deep/ .el-dialog {
        border-radius: .2rem;
    }

    /deep/ .el-dialog__header {
        padding: 0;
    }

    /deep/ .el-dialog__body {
        padding: 0;
        border-radius: .2rem;
    }

    .container {
        position: relative;

        .left-img {
            width: 4rem;
            height: 5.33rem;

            img {
                width: 100%;
                height: 100%;
                border-radius: .2rem 0 0 .2rem;
            }
        }

        .close-icon {
            position: absolute;
            top: .24rem;
            right: .24rem;

            img {
                width: .32rem;
                height: .32rem;
            }
        }

        .right-content {
            flex: 1;
            height: 5.33rem;
            box-sizing: border-box;
            padding: .32rem .12rem .32rem .28rem;

            .top {
                margin-bottom: .2rem;

                .name {
                    color: #000;
                    font-weight: 500;
                    font-size: .24rem;
                    line-height: .32rem;
                    display: inline-block;
                    margin-right: .16rem;
                }
                
                .license {
                    height: .22rem;
                    line-height: .22rem;
                    color: var(--textColor);
                    display: inline-block;
                }
                
                .el-icon-d-arrow-right {
                    font-weight: bold;
                    font-size: 12px;
                    margin-left: .04rem;
                    color: var(--textColor);
                }
            }

            .introduction {
                flex: 1;
                font-size: .16rem;
                line-height: .28rem;
                color: rgba(0, 0, 0, 0.8);
                padding-right: .38rem;
                overflow: auto;
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 10px;
                    height: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 6px;
                    border: 2px solid white;
                    background-color: rgba(0, 0, 0, .3);
                }
            }
        }
    }

    /deep/ .mobile-dialog{
        border-radius: .12rem;
        .mobile-container{
            width: 3.51rem;
            height: 4.78rem;
            overflow: auto;
            padding: .1rem .12rem;
            box-sizing: border-box;
            .close-icon{
                width: .32rem;
                height: .32rem;
                right: 0;
                top: -.47rem;
                position: absolute;
            }
            .name{
                color: #000;
                font-weight: 500;
                font-size: .22rem;
                line-height: .3rem;
                margin-top: .06rem;
            }
            .license{
                margin: .08rem 0 .1rem;
                height: .22rem;
                color: var(--textColor);
                .el-icon-d-arrow-right{
                    font-weight: bold;
                    font-size: 12px;
                    margin-left: .04rem;
                    color: var(--textColor);
                }
            }
            .introduction{
                font-size: .14rem;
                line-height: .28rem;
                color: rgba(0,0,0,0.8);
            }
            .people-img{
                float: right;
                width: 1.76rem;
                height: 2.34rem;
                border-radius: .04rem;
                margin: 0 0 .1rem .12rem;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            &::before,&::after{
                content: '';
                position: absolute;
                width: 100%;
                height: .1rem;
                left: 0;
                background: #fff;
                
            }
            &::before{
                top: 0;
                border-radius: .12rem .12rem 0 0;
            }
            &::after{
                bottom: 0;
                border-radius: 0 0 .12rem .12rem;
            }
        }
    }
}
</style>