import HomeViewVue from "@/views/HomeView.vue";

export default {
    main:{
      message:"message",
      display:"display"
    },
    HomeViewVue:{
      select:{
        country:'Country',
        china:'China',
        usa:'USA',
        CA:'Canada',
        language:'Language',
        zn:'Chinese',
        en:'English',
      },
      nav:{
        nav1:'Introduction to Canada',
        nav2:'Immigrant',
        nav3:'Study Abroad',
        nav4:'Travel',
        nav5:'Work',
        nav6:'Life',
        nav7:'Curriculum',
        nav8:'Tool',
      }
    }
}  