export default {
    main:{
      message:"消息",
      display:"展示"
    },
    HomeViewVue:{
      select:{
        country:'国家',
        china:'中国',
        usa:'美国',
        CA:'加拿大',
        language:'语言',
        zn:'中文',
        en:'英文',
      },
      nav:{
        nav1:'加拿大介绍',
        nav2:'移民',
        nav3:'留学',
        nav4:'旅游',
        nav5:'工作',
        nav6:'生活',
        nav7:'课程',
        nav8:'工具',
      }
    }
}  