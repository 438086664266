// 首页模块
import request from '@/request/request'


export const getConfigApi = () => request.post('/externalAPI/website/commons/getConfig') // 获取当前大脚的ip配置

export const getDirectoryListApi = (params) => request.post('/externalAPI/website/commons/getDirectoryList',params) // 获取目录列表

export const getHomeDataApi = (params) => request.post('/externalAPI/website/home/getData',params) // 获取首页数据

export const getTeamDataApi = (params) => request.post('/externalAPI/website/team/queryList',params) // 获取团队数据

export const getTeamListApi = (params) => request.post('/externalAPI/website/team/getData',params) // 获取团队数据

export const getbusinessTypeListApi = (params) => request.post('/externalAPI/website/project/businessTypeList',params) // 获取项目业务类型

export const getprojectListApi = (params) => request.post('/externalAPI/website/project/getData',params) // 获取项目列表数据

export const getprojectDetailApi = (params) => request.post(`/externalAPI/website/project/detail`,params) // 获取项目详情

export const getNewsTagListAllApi = (params) => request.post('/externalAPI/website/news/newsTagListAll',params)// 获取新闻标签列表

export const getNewsListApi = (params) => request.post('/externalAPI/website/news/getData',params)// 获取新闻列表

export const getNewsDetailApi = (params) => request.post(`/externalAPI/website/news/detail`,params) // 获取新闻详情

export const getRecommendListApi = (params) => request.post(`/externalAPI/website/news/recommendList`,params) // 获取新闻推荐列表

export const getBannerListApi = (params) => request.post(`/externalAPI/website/commons/getBannerList`,params) // 获取项目bannerList

export const getcaseListApi = (params) => request.post(`/externalAPI/website/case/getData`,params) // 获取案例列表数据

export const getcaseBusinessListApi = (params) => request.post(`/externalAPI/website/case/businessList`,params) // 获取案例列表数据


