import Vue from 'vue'
import VueRouter from 'vue-router'
// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/homeView'
  },
  {
    path: '/homeView',
    name: 'homeView',
    redirect: '/homeView/home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeViewNew.vue'),
    children:[
      {
        path:"/homeView/home",  // 首页
        name:"home",
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
      },
      {
        path:"/homeView/case",  // 案例
        name:"case",
        component: () => import(/* webpackChunkName: "case" */ '@/views/case/index.vue'),
      },
      {
        path:"/homeView/team",  // 团队
        name:"team",
        component: () => import(/* webpackChunkName: "team" */ '@/views/team/index.vue'),
      },
      {
        path:"/homeView/news",  // 新闻
        name:"news",
        component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/news/index.vue'),
      },
      {
        path:"/homeView/newsDetail/*",  // 新闻详情
        name:"newsDetail",
        component: () => import(/* webpackChunkName: "newsDetail" */ '@/views/news/newsDetail.vue'),
      },
      {
        path:"/homeView/project",  // 项目详情
        name:"project",
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/index.vue'),
      },
      {
        path:"/homeView/project/*",  // 项目详情
        name:"projectDetail",
        component: () => import(/* webpackChunkName: "projectDetail" */ '@/views/project/projectDetail.vue'),
      },
      {
        path:"/homeView/integral",  // 项目详情
        name:"integral",
        component: () => import(/* webpackChunkName: "integral" */ '@/views/integral/index.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
