import axios from "axios"; // 引入axios
import router from "@/router/index";
import { baseURL } from "@/config";
// 创建 instance实例
const instance = axios.create({
  baseURL,
  timeout: 500000000000000,
});
// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["accessToken"] = token;
    }
    return config; // 一定要返回config,不然在组件中会报错
  },
  (err) => {
    return Promise.reject(err);
  }
);
// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    let data = res.data;
    if (data.code != 200) {
      // ELEMENT.Message.error(data.msg || "网络请求错误");
    }
    if (data.code == 4001 || data.code == 4002 || data.code == 4003) {
      router.push("/login");
    }
    return data; // 这个返回的组件中then方法中的数据
  },
  (err) => {
    return Promise.reject(err);
  }
);
export default instance;
