import Vue from 'vue'
import App from './App.vue'

import peopleDetail from '@/components/pc/peopleDetail.vue'
import lazyLoad from '@/directive/lazy'
import "normalize.css/normalize.css"
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';

import i18n from '@/lang'

import router from './router'
import store from './store'


// Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.directive('img-lazy',lazyLoad)
Vue.component('peopleDetail' ,peopleDetail)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
