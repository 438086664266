// 域名
// export const domainName = "http://43.136.117.217:9710/immi-admin";
let baseURL = "";
let basFileURL = "";

if (process.env.NODE_ENV == "development") {
  baseURL = "https://api.test.dajiaochuguo.com/immi-admin";
} else {
  let protocol = window.location.protocol;
  let port = window.location.port;
  let fullUrl = protocol + "//" + window.location.hostname;

  if (port) {
    fullUrl += ":" + port;
  }
  baseURL = `${fullUrl}/api/`;
}

export { baseURL, basFileURL };
