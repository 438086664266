<template>
  <div class="home_view">
    <!-- PC端 -->
    <div class="web_box">
      <div class="top_box">
        <!-- 头部导航 -->
        <div>
          <div class="banxin flexbox right middle">
            <div class="select_top flexbox middle right">
              <div class="menu_list flexbox middle right" v-if="false">
                <div class="item" v-for="(item, index) in menuArr" :key="index" @click="navClick(index)">
                  <div class="flexbox middle" v-if="index == 5">
                    <span>(</span>
                    <span>{{ item }}</span>
                    <div class="flexbox middle">
                      <span>：</span>
                      <img :src="XSH" alt="">
                      <img :src="ZH" alt="">
                    </div>
                    <span>)</span>
                  </div>
                  <div v-else>{{ item }}</div>
                </div>
              </div>
              <div class="flexbox middle">
                <!-- 国家 -->
                <div class="country flex">
                  <span class="label">{{ $t('HomeViewVue.select.country') }}</span>
                  <el-select size="small" v-model="country" placeholder="请选择" @change="CountryChange">
                    <el-option v-for="item in countryOptions" :key="item.value" :value="item.value" :label="item.label">
                      <div class="flex">
                        <img :src="item.src" alt="" style="width: 24px;height: 24px;">
                        <span style="margin-left: 4px;">{{ item.label }}</span>
                      </div>
                    </el-option>
                    <template slot="prefix">
                      <div class="flex" style="margin-left: .04rem;">
                        <img :src="countryImg[country].img" alt="" style="width: 24px;height: 24px;">
                      </div>
                    </template>
                  </el-select>
                </div>
                <!-- 语言 -->
                <div class="language flex">
                  <span class="label">{{ $t('HomeViewVue.select.language') }}</span>
                  <el-select size="small" v-model="language" placeholder="请选择" @change="languageChange">
                    <el-option v-for="item in languageOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <!-- logo和标签 -->
        <div class="list_box flexbox">
          <div class="logo" @click="navClick(0)">
            <img v-if="logo" :src="logo" alt="">
          </div>
          <ul class="flexbox middle wrap">
            <li v-for="(item, index) in navArr" :key="item.directoryNo" @mouseleave="mouseleave">
              <!-- 一级 -->
              <span v-if="item.children.length || !item.link" class="text one-t">{{ item.name }}</span>
              <a v-else :href="item.link" target="_blank" class="text one-t">{{ item.name }}</a>
              <!-- 二级 -->
              <div class="menu_box" v-if="item.children.length"
                :style="`--height:${(((item.children.length) * 20) + ((item.children.length - 1) * 16) + 40) / 100}rem`">
                <div class="menu_item flex one-t" v-for="(subItem, subIndex) in item.children" :key="subItem.directoryNo"
                  @mouseenter="mouseenter(subItem, subIndex, index)">
                  <span v-if="subItem.children.length || !subItem.link" class="name one-t">{{ subItem.name }}</span>
                  <a v-else :href="subItem.link" target="_blank" class="name one-t">{{ subItem.name }}</a>
                  <i class="el-icon-arrow-right" v-if="subItem.children.length"></i>
                </div>
                <!-- 三级 -->
                <template v-show="thirdDirectoryArr.length">
                  <div
                    :class="[`third-box third-box-${index}`, thirdDirectoryArr.length ? 'active' : '', left ? 'third-left' : 'third-right']"
                    :style="{ '--top': `${(thirdIndex * 36) - 16}px`, '--height': `${(((thirdDirectoryArr.length) * 20) + ((thirdDirectoryArr.length - 1) * 16) + 40) / 100}rem` }">
                    <div class="item" v-for="e in thirdDirectoryArr" :key="e.directoryNo">
                      <span v-if="!e.link" class="name one-t">{{ e.name }}</span>
                      <a v-else :href="e.link" target="_blank" class="name one-t">{{ e.name }}</a>
                    </div>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
        <div class="line" style="background-color: rgba(17,17,17,0.08);"></div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="mobile_box">
      <!-- logo头部 -->
      <div class="mobile_top">
        <img class="menu_icon" v-if="!menuShow" :src="menuIcon" alt="menu" @click="$store.commit('menuShowChange', true)">
        <img class="menu_icon" v-else :src="menuClose" alt="menu" @click="$store.commit('menuShowChange', false)">
        <div class="logo" @click="navClick(0, 'logo')">
          <img v-if="logo" :src="logo" alt="大脚出国">
        </div>
      </div>
      <!-- 左边菜单 -->
      <div class="nav_menu flexbox" :class="menuShow ? 'menu_show' : ''">
        <div class="left_menu" @touchmove="touchmove">
          <div class="menu_content">
            <!-- 导航 -->
            <!-- <div class="nav_top flexbox wrap">
              <div class="item" v-for="(item, index) in menuArr" :key="index" @click="navClick(index)">
                <div class="flexbox middle" v-if="index == 5">
                  <span>(</span>
                  <span>{{ item }}</span>
                  <div class="flexbox middle">
                    <span>：</span>
                    <img :src="XSH" alt="">
                    <img :src="ZH" alt="">
                  </div>
                  <span>)</span>
                </div>
                <div v-else>{{ item }}</div>
              </div>
            </div> -->
            <!-- 筛选 -->
            <div class="select_top">
              <!-- 国家 -->
              <div class="country flex">
                <span class="label">{{ $t('HomeViewVue.select.country') }}</span>
                <el-select size="small" v-model="country" placeholder="请选择" style="width: 1.9rem;;">
                  <el-option v-for="item in countryOptions" :key="item.value" :value="item.value" :label="item.label">
                    <div class="flex">
                      <img :src="item.src" alt="" style="width: 24px;height: 24px;">
                      <span style="margin-left: 4px;font-size: .12rem;">{{ item.label }}</span>
                    </div>
                  </el-option>
                  <template slot="prefix">
                    <div class="flex" style="margin-left: .04rem;">
                      <img :src="countryImg[country].img" alt="" style="width: 24px;height: 24px;">
                    </div>
                  </template>
                </el-select>
              </div>
              <!-- 语言 -->
              <div class="language flex">
                <span class="label">{{ $t('HomeViewVue.select.language') }}</span>
                <el-select size="small" v-model="language" placeholder="请选择" @change="languageChange"
                  style="width: 1.9rem;">
                  <el-option v-for="item in languageOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 标签选择 -->
            <div class="tag_list">
              <div class="item" v-for="(item, index) in navArr" :key="item.directoryNo">
                <!-- 一级 -->
                <div class="first flexbox middle justify" @click="item.open = !item.open">
                  <div v-if="item.children.length || !item.link" class="name one-t">{{ item.name }}</div>
                  <a v-else :href="item.link" class="name one-t" target="_blank">{{ item.name }}</a>
                  <img v-if="item.children.length" :class="item.open ? 'tag-show' : ''" :src="close">
                </div>
                <!-- 二级 -->
                <div class="second" :class="item.open && item.children.length ? 'second_show' : ''"
                  :style="`--height:${getHeight(item)}`">
                  <template v-for="(subItem, subIndex) in item.children">
                    <div class="second_item" :key="subItem.directoryNo">
                      <div v-if="subItem.children.length || !subItem.link" class="one-t fz-13">{{ subItem.name }}</div>
                      <a v-else :href="subItem.link" target="_blank" class="one-t fz-13">{{ subItem.name }}</a>
                    </div>
                    <!-- 三级 -->
                    <div class="third">
                      <template v-for="e in subItem.children">
                        <div class="third_item" :key="e.directoryNo">
                          <div v-if="!e.link" class="one-t fz-12">{{ e.name }}</div>
                          <a v-else :href="e.link" target="_blank" class="one-t fz-12">{{ e.name }}</a>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_mask flex1" @click="$store.commit('menuShowChange', false)" @touchmove.prevent></div>
      </div>
    </div>
    <transition name="moveCartoon" mode="out-in">
      <router-view />
    </transition>
    <div class="web_footer">
      <Footer :data="this.navFooterArr"></Footer>
    </div>
    <div class="mobile_footer">
      <MobileFooter :data="this.navFooterArr"></MobileFooter>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang'
import usa from '@/assets/images/USA.png'
import china from '@/assets/images/China.png'
import canada from '@/assets/images/Canada.png'

import { mapState } from 'vuex'
import Footer from "@/components/pc/Footer.vue";
import { getDirectoryListApi } from '@/request/api/home'
import MobileFooter from "@/components/mobile/MobileFooter.vue";
import { CountryData, LanguageData, navData, getprojectDetailApi } from '@/utils/data'
export default {
  name: "HomeView",
  components: {
    Footer, MobileFooter
  },
  data() {
    return {
      country: 'CA',
      language: localStorage.getItem('default-locale') || 'zn',
      activeIndex: 1,
      navArr: [],
      navFooterArr: [],
      menuArr: ['首页', '最新新闻&研讨会', '关于我们', '联系我们', '注册成为会员', '新媒体链接'],
      countryOptions: [],
      languageOptions: [],
      navIndex: 0,
      scrollTop: 0,
      countryImg: {
        china: { value: '中国', img: china },
        CA: { value: '加拿大', img: canada },
        usa: { value: '美国', img: usa },
      },
      XSH: require('@/assets/images/xiaohongshu2.png'),
      ZH: require('@/assets/images/zhihu2.png'),

      // 预存三级目录
      thirdDirectoryArr: [],
      thirdIndex: 0,
      left: false,
      timeOut: null,

      // mobile
      close: require('@/assets/images/mobile/tag-close.png'),
      menuIcon: require('@/assets/images/mobile/menu-icon.png'),
      menuClose: require('@/assets/images/mobile/menu-close.png'),
    };
  },
  watch: {
    language: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.initData()
        }
      }
    },
    orgId: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.getDirectoryList()
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['orgId', 'region', 'menuShow', 'logo']),
  },
  created() {
    this.initData()
    if (this.navIndex == 0) {
      // window.addEventListener("scroll", this.handleScroll, true);
    }

    this.$router.push(this.$route.path)
  },
  mounted() {
    this.getConfig()
    this.getDirectoryList()
    // 语言储存
    const region = localStorage.getItem('region')
    if (region) {
      this.country = region
      this.$store.commit('regionSet', region)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
    this.timeOut = null
  },
  methods: {
    // 语言切换
    languageChange() {
      this.$i18n.locale = this.language
      localStorage.setItem('default-locale', this.language)
    },
    // 国家选择
    CountryChange() {
      this.$store.commit('regionSet', this.country)
      localStorage.setItem('region', this.country)
    },
    // 语言切换初始化组件数据
    initData() {
      this.countryOptions = []
      for (const key in this.countryImg) {
        if (key == 'CA') {
          this.countryOptions.push({
            value: key,
            label: i18n.t(`HomeViewVue.select.${key}`),
            src: this.countryImg[key].img
          })
        }
      }
      this.languageOptions = [
        {
          value: 'zn',
          label: i18n.t('HomeViewVue.select.zn'),
        },
        // {
        //   value: 'en',
        //   label: i18n.t('HomeViewVue.select.en'),
        // },
      ]
    },
    navClick(index, type = '') {
      if (index != 0) return
      this.navIndex = index;
      switch (this.navIndex) {
        case 0:
          this.$router.push("/homeView/home");
          if (type == 'logo') {
            this.$store.commit('menuShowChange', false)
          }
          window.scrollTo(0, 0);
          break;
        default:
      }
    },
    handleScroll() {
      this.scrollTop =
        document.body.scrollTop ||
        document.documentElement.scrollTop ||
        window.pageYOffset;
    },
    // 获取当前用户配置
    getConfig() {
      this.$store.dispatch('GETCONFIG')
    },
    httpMethod(link) {
      return link && link.slice(0, 7) != 'http://' && link.slice(0, 8) != 'https://'
    },
    // 处理网址没有http的情况
    processLink(obj) {
      const processedObj = { ...obj };
      if (this.httpMethod(processedObj.link)) {
        processedObj.link = `http://${processedObj.link}`;
      }
      if (processedObj.children && processedObj.children.length > 0) {
        processedObj.children = processedObj.children.map((child) =>
          this.processLink(child)
        );
      }
      return processedObj;
    },
    // 由后台控制
    processNavArr(arr, type) {
      return arr
        .filter(item => item[type] === 1).map(item => ({
          ...item,
          children: item.children.length > 0 ? this.processNavArr(item.children, type) : []
        }));

    },
    // 获取目录
    async getDirectoryList() {
      let _this = this
      const params = {
        orgId: this.orgId,
        region: this.country
      }
      const res = await getDirectoryListApi(params)
      if (res.code === 200) {
        const data = res.data.map(item => {
          const processedItem = _this.processLink(item);
          processedItem.open = false;
          return processedItem;
        })
        this.navArr = _this.processNavArr(data, 'headDisplayFlag');
        this.navFooterArr = _this.processNavArr(data, 'footerDisplayFlag');
        // 爱舞功 - orgId == 1
        if (this.orgId !== 1) {
          this.navFooterArr = this.navFooterArr.slice(0, 5);
          return
        } else if (this.navArr.length) {
          const filterConditions = [
            ['加拿大移民', '移民'],
            ['加拿大留学', '留学'],
            ['加拿大旅游', '旅游'],
            ['加拿大工作', '工作'],
            ['工具'],
          ];
          this.navFooterArr = filterConditions.map(conditions =>
            this.navFooterArr.find(el => conditions.some(condition => el.name === condition))
          ).filter(Boolean);
        }
      }
    },

    // 二级目录鼠标移入 显示三级目录
    mouseenter(item, index, firstIndex) {
      this.thirdIndex = index
      this.thirdDirectoryArr = item.children
      if (!this.thirdDirectoryArr.length) return
      this.timeOut = setTimeout(() => {
        // 三级目录
        this.left = false
        this.$nextTick(() => {
          const element = document.querySelector(`.third-box-${firstIndex}`)
          const rect = element.getBoundingClientRect()
          // 计算元素到屏幕右边的距离
          const HasRight = window.innerWidth - rect.left - 20
          if (HasRight < 260) {
            this.left = true
          } else {
            this.left = false
          }
        })

      }, 100);
    },
    mouseleave() {
      this.thirdIndex = 0
      this.thirdDirectoryArr = []
    },
    getHeight(item) {
      let sum = item.children.length * 20 + ((item.children.length * 12) + 4)
      // 加上3级目录
      item.children.forEach(el => {
        if (el.children.length) {
          sum += (el.children.length * 20 + el.children.length * 12)
        }
      });
      return sum / 100 + 'rem'
    },
    touchmove(e){
      const elementPerson = document.querySelector('.left_menu')
      const elementSon = document.querySelector('.menu_content')
      const person = elementPerson.getBoundingClientRect()
      const son = elementSon.getBoundingClientRect()
      if(son.height > (person.height - 32)) return
      e.preventDefault();
    }
  }
};
</script>
<style lang="less" scoped>
// .moveCartoon-enter-active,
// .moveCartoon-leave-avtive {

//   transition: opacity 1s
// }

// .moveCartoon-enter,
// .moveCartoon-leave-to {

//   opacity: 0
// }

.moveCartoon-enter-active,
.moveCartoon-leave-active {
  transition: opacity .5s linear;
}

.moveCartoon-enter-from,
.moveCartoon-leave-to {
  opacity: 0;
}

@hover_color: var(--textColor);
@active_color: var(--textColor);

li {
  list-style: none;
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.nav-active {
  font-weight: 600;
  color: @active_color;
  border-bottom: 2px solid @active_color;
}


// 国家筛选
.country {
  margin-right: .24rem;

  /deep/ .el-input__prefix {
    display: flex;
  }

  /deep/ .el-input__inner {
    padding-left: .37rem !important;
  }
}

.web_box {
  // padding-top: .72rem;
  padding-top: 1.68rem;

  .top_box {

    // height: 1.68rem;
    // position: relative;
    position: fixed;
    width: 100%;
    height: 1.68rem;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: #fff;

    // 顶部
    .select_top {
      width: 100%;
      height: .72rem;

      /deep/ .el-input {
        width: 1.4rem !important;
      }

      .label {

        margin-right: .08rem;
      }

      .menu_list {
        flex: 1;
        margin-right: .56rem;

        .item {
          cursor: pointer;
          line-height: .22rem;
          margin-left: .35rem;
          color: rgba(0, 0, 0, 0.6);

          img {
            width: .2rem;
            height: .2rem;
            margin-right: .12rem;
          }
        }
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, 0.2);
    }

    .fixed {
      position: fixed;
      width: 100%;
      height: .72rem;
      left: 0;
      top: 0;
      z-index: 999;
      background-color: #fff;
    }

    // logo右边导航
    .list_box {
      height: .95rem;
      width: 1400px;
      max-width: 14rem;
      margin: 0 auto;
      align-items: center;

      .logo {
        cursor: pointer;
        // margin-top: .08rem;
        margin-right: .65rem;
        height: 100%;
        display: flex;
        align-items: center;

        img {
          // width: 2rem;
          // height: .55rem;
          max-width: 2rem;
          max-height: .55rem;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        height: .22rem;
        // margin-top: .32rem;
        max-width: 11.35rem;
        min-width: 11.35rem;
        align-content: center;
        li {
          font-size: .14rem;
          position: relative;
          padding: .08rem .16rem;
          box-sizing: border-box;
          border-bottom: 2px solid transparent;

          &:hover {
            border-bottom: 2px solid @hover_color;
          }

          &:hover .text {
            // font-weight: 600;
            color: @hover_color;
          }

          &:not(:last-child)::after {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0.01rem;
            height: .16rem;
            content: '';
            position: absolute;
            background-color: rgba(0, 0, 0, 0.2);
          }

          .text {
            color: rgba(0, 0, 0, 0.8);
            cursor: pointer;
            display: inline-block;
            width: 100%;
            max-width: 160px;

            &::after {
              display: block;
              content: attr(title);
              font-weight: bold;
              visibility: hidden;
              height: 1px;
              color: transparent;
              margin-bottom: -1px;
              overflow: hidden;
            }
          }

          .menu_box {
            left: 0;
            height: 0;
            opacity: 0;
            z-index: 0;
            width: 2.6rem;
            padding: .2rem;
            overflow: hidden;
            background: #fff;
            visibility: hidden;
            position: absolute;
            border-radius: 4px;
            box-sizing: border-box;
            transition: .3s ease-in-out;
            transform: translateY(.16rem);
            box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.16), 0px 3px 6px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px -2px rgba(0, 0, 0, 0.16);

            .menu_item {
              height: .2rem;
              cursor: pointer;
              line-height: .2rem;
              justify-content: space-between;

              &:not(:last-child) {
                margin-bottom: .16rem;
              }

              .name {
                width: 2.1rem;
                display: block;
                max-width: 2.1rem;
                color: rgba(0, 0, 0, 0.8);

                &:hover {
                  color: @hover_color;
                }

                &::after {
                  display: block;
                  content: attr(title);
                  visibility: hidden;
                  height: 1px;
                  color: transparent;
                  margin-bottom: -1px;
                  overflow: hidden;
                }
              }
            }
          }

          &:hover .menu_box {
            opacity: 1;
            z-index: 100;
            overflow: visible;
            visibility: visible;
            height: var(--height);
          }
        }
      }
    }
  }

}

// 手机端样式
.mobile_box {
  width: 100%;
  height: 100%;
  // min-height: 100vh;
  background-color: #fff;
  padding-top: .66rem;
  box-sizing: border-box;
  // transform: translate(0%, 0%);

  .mobile_top {
    width: 100%;
    height: 0.66rem;
    padding: 0 .12rem;
    box-sizing: border-box;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
    border-bottom: 1px solid rgba(17, 17, 17, 0.08);

    .menu_icon {
      width: 0.32rem;
      height: 0.32rem;
      margin-top: .18rem;
    }

    .logo {
      flex: 1;
      display: flex;
      justify-content: center;

      img {
        // width: 1.6rem;
        // height: .44rem;
        max-width: 1.6rem;
        max-height: .44rem;
        margin-top: .06rem;
        transform: translateX(-0.06rem);
      }
    }
  }

  // .mask {
  //   width: 100%;
  //   height: calc(100vh - .66rem);
  //   position: absolute;
  //   left: 0;
  //   top: .66rem;
  //   z-index: 998;

  // }

  .nav_menu {
    width: 0;
    position: fixed;
    left: 0;
    top: .66rem;
    z-index: 0;
    height: calc(100vh - .66rem);
    background: transparent;
    transition: .4s ease;
    opacity: 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .left_menu {
    width: 2.6rem;
    padding: .16rem;
    box-sizing: border-box;
    background-color: #fff;
    overflow: scroll;
    overscroll-behavior-y: contain;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .right_mask {
    background: rgba(0, 0, 0, 0.2);
  }

  .menu_show {
    width: 100vw;
    opacity: 1;
    z-index: 999;
    transition: .4s ease;
  }

  .menu_content {
    width: 2.28rem;

    // margin: .16rem;
    .label {
      width: .26rem;
      min-width: .26rem;
      font-size: .12rem;
      line-height: .2rem;
      margin-right: .06rem;
    }

    .nav_top {
      margin-bottom: .16rem;

      .item {
        font-size: .12rem;
        line-height: .2rem;
        font-weight: 500;
        margin-right: .24rem;

        &:nth-of-type(3) {
          margin-right: 0;
        }

        &:last-of-type {
          img {
            width: .2rem;
            height: .2rem;
            margin-right: .08rem;
          }
        }
      }
    }

    .country {
      margin: 0 0 .16rem !important;
    }

    /deep/ .el-input__inner {
      font-size: .12rem;
    }

    .tag_list {
      margin-top: .16rem;
      overflow-y: auto;

      .item {
        padding: .16rem 0;
        overflow: hidden;
        border-bottom: 1px solid rgba(17, 17, 17, 0.08);
        color: rgba(0, 0, 0, 0.8);

        a {
          color: inherit;
        }

        .name {
          line-height: .2rem;
          color: rgba(0, 0, 0, 0.8);
        }

        .fz-14 {
          font-size: 14px;
        }

        .fz-13 {
          font-size: 13px;
        }

        .fz-12 {
          font-size: 12px;
        }


        img {
          width: .14rem;
          height: .14rem;
          transition: .2s;
        }

        .tag-show {
          transform: rotate(-180deg);
        }
      }

      .second {
        height: 0;
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        margin-left: .12rem;
        transition: .2s linear;

        .second_item {
          font-size: .12rem;
          margin-top: .12rem;
          line-height: .2rem;

          a {
            display: block;
            width: inherit;
          }

          &:first-child {
            margin-top: .16rem;
          }
        }
      }

      .second_show {
        opacity: 1;
        height: var(--height);
        transition: .2s linear;
        visibility: visible;
      }

      .third {
        margin-left: .12rem;

        .third_item {
          margin-top: .12rem;
          line-height: .2rem;

          a {
            display: block;
            width: inherit;
          }
        }
      }

    }
  }
}

.third-box {
  top: var(--top);
  height: 0;
  width: 2.6rem;
  opacity: 0;
  background: #fff;
  position: absolute;
  box-sizing: border-box;
  border-radius: 4px;
  transition: .3s ease-in-out;
  transform: translateY(.16rem);
  box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.05), 0px 3px 6px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px -2px rgba(0, 0, 0, 0.05);

  .item {
    padding: 0 .2rem;
    cursor: pointer;
    line-height: .2rem;
    margin-bottom: .16rem;

    &:first-of-type {
      padding-top: .2rem;
    }

    &:last-of-type {
      padding-bottom: .2rem;
      margin-bottom: 0;
    }

    .name {
      width: 2.1rem;
      display: block;
      max-width: 2.1rem;
      color: rgba(0, 0, 0, 0.8);
      ;
    }

    &:hover .name {
      color: var(--textColor);
      cursor: pointer;
    }
  }

  &.active {
    opacity: 1;
    height: var(--height);
    transition: .3s ease-in-out;
  }

  &.third-left {
    left: -2.6rem;
  }

  &.third-right {
    right: -2.6rem;
  }

}


@media screen and (max-width: 600px) {
  .web_box {
    display: none;
  }

  .web_footer {
    display: none;
  }
}

@media (min-width: 600px) and (max-width: 1400px) {
  .web_box {

    .list_box {
      width: 1200px !important;
      ul{
        max-width: 9.35rem!important;
        min-width: 9.35rem!important;
      }
    }
  }

  .mobile_footer {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .mobile_box {
    display: none;
  }

  .mobile_footer {
    display: none;
  }
}
</style>
<style lang="less">
.right-more {
  cursor: pointer;
  position: relative;
  overflow: hidden;

  span {
    color: var(--textColor);
    font-size: .16rem;
    line-height: .24rem;
    line-height: .24rem;
    margin-right: 0.04rem;
  }

  img {
    width: 0.12rem;
    height: 0.12rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -110%;
    width: 100%;
    height: 100%;
    transform: skew(-30deg);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    transition: all .5s;
  }

  &:hover::before {
    left: 110%;
  }

  &:hover {
    // box-shadow: 0 2px 12px rgba(0, 0, 0, .3);
    // transform: translateY(-2px);
    transition: all .3s;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--textColor);
    transition: all .5s;
    opacity: 0;
  }

  &:hover::after {
    width: 100%;
    opacity: 1;
  }
}
</style>

